/* SubmissionDetails.css */

/* General Styling */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  /* Card Styling */
  .submission-card {
    padding: 0;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  .submission-card .ant-card-body{
    padding: 0;
  }
  .section-title {
    margin: 0;
    margin-top: 2.5rem!important;
    margin-bottom: 2rem!important;
    font-size: 25px;
    font-weight: 700;
    color: #333;
  }
  
  .general-info-section {

    font-size: 1rem;
    color: #555;
  }
  
  .general-info-section p {
    margin: 0.5rem 0;
  }
  
  .back-button {
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    line-height: normal;
    padding-left: 0;
  }
  
  .back-button:hover {
    background-color: #40a9ff;
  }
  
  /* Table Styling */
  .ant-table {
    border-radius: 8px;
    overflow: hidden;
  }
  
  .ant-table-thead > tr > th {
    background-color: #f0f2f5;
    color: #000;
    font-weight: 600;
    border-bottom: 2px solid #e8e8e8;
  }
  
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
  }
  
  .ant-table-row {
    transition: background-color 0.2s ease;
  }
  
  .ant-table-row:nth-child(odd) {
    background-color: #fafafa;
  }
  
  .ant-table-row:hover {
    background-color: #e6f7ff;
  }
  
  /* Modal Styling */
  .ant-modal-content {
    border-radius: 8px;
  }
  
  .ant-modal-header {
    background-color: #1890ff;
    color: white;
  }
  
  .ant-modal-title {
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .ant-modal-footer {
    border-top: 1px solid #e8e8e8;
  }
  
  .ant-modal-close-x {
    color: #ffffff;
  }
  
  .ant-modal-close-x:hover {
    color: #ff4d4f;
  }
  .dark-btn-hover:hover{
    opacity: 0.8;
  }
  .light-btn-hover:hover{
    border: "1px solid #fff";
            color: "#fff";
            background-color: "#5A7890";
  }
  