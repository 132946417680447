.user-table-box{
    background:$light-color;
    padding:24px 24px;
    border-radius:8px;
    box-shadow: 0px 4px 48px 0px #5B5B5B0D;

}

.main-layout{
    padding:17px;
    margin-top:20px ;
    background:$light-color;
    border-radius: 8px;
}

