@import "base/variable";
@import "base/theme";
@import "base/spinner";
@import "base/user";

.p-4{
    padding: 24px !important;
}

.tenant-detail-information-wrap{
    margin-bottom: 24px;
}

.tenant-action-wrap{
    display: flex;
    flex-direction: column;
}

.tenant-details-heading{
    margin-bottom: 16px !important;
}

.tenant-detail-main-card .ant-table-wrapper{
    margin-bottom: 40px !important;
}
.tenant-detail-main-card .ant-table-wrapper table{
    width: auto !important;
}