.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  cursor: pointer;
}
.drawing {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  cursor: pointer;
}

.loading-dot {
  border-radius: 100%;
  height: 10px;
  width: 10px;
  background-color: $theme-color;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  box-shadow: 0px 40px 0px 0px $theme-color,
              0px -40px 0px 0px $theme-color,
              40px 0px 0px 0px $theme-color,
              -40px 0px 0px 0px $theme-color,
              40px 40px 0px 0px $theme-color,
              -40px -40px 0px 0px $theme-color,
              40px -40px 0px 0px $theme-color,
              -40px 40px 0px 0px $theme-color;
  animation: load 3s infinite linear;
}

.section-spin{
  width:20% !important;
  margin-bottom:1rem;
}

@keyframes load {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
    box-shadow: 0px 40px 0px 0px $theme-color,
                0px -40px 0px 0px $theme-color,
                40px 0px 0px 0px $theme-color,
                -40px 0px 0px 0px $theme-color,
                40px 40px 0px 0px $theme-color,
                -40px -40px 0px 0px $theme-color,
                40px -40px 0px 0px $theme-color,
                -40px 40px 0px 0px $theme-color;
  }
  
  25% {
    box-shadow: 0px 40px 0px 0px $light-color,
                0px -40px 0px 0px $light-color,
                40px 0px 0px 0px $light-color,
                -40px 0px 0px 0px $light-color,
                -40px -40px 0px 0px $light-color,
                40px 40px 0px 0px $light-color,
                -40px 40px 0px 0px $light-color,
                40px -40px 0px 0px $light-color;
  }
  
  50% {
    box-shadow: 0px -40px 0px 0px $light-color,
                0px 40px 0px 0px $light-color,
                -40px 0px 0px 0px $light-color,
                40px 0px 0px 0px $light-color,
                -40px -40px 0px 0px $light-color,
                40px 40px 0px 0px $light-color,
                -40px 40px 0px 0px $light-color,
                40px -40px 0px 0px $light-color;
  }

  70% {
    box-shadow: 0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color;
  }

  75% {
    box-shadow: 0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color;
    height: 10px;
    width: 10px;
  }

  80% {
    transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color;
    background-color: $light-color;
    height: 40px;
    width: 40px;
  }

  85% {
    transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color,
                0px 0px 0px 0px $light-color;
    background-color: $light-color;
    height: 40px;
    width: 40px;
  }

  90% {
    transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px $theme-color,
                0px 0px 0px 0px $theme-color,
                0px 0px 0px 0px $theme-color,
                0px 0px 0px 0px $theme-color,
                0px 0px 0px 0px $theme-color,
                0px 0px 0px 0px $theme-color,
                0px 0px 0px 0px $theme-color,
                0px 0px 0px 0px $theme-color;
    background-color: $theme-color;
    height: 10px;
    width: 10px;
  }

  95% {
    box-shadow: 0px 40px 0px 0px $light-color,
                0px -40px 0px 0px $light-color,
                40px 0px 0px 0px $light-color,
                -40px 0px 0px 0px $light-color,
                40px 40px 0px 0px $light-color,
                -40px -40px 0px 0px $light-color,
                40px -40px 0px 0px $light-color,
                -40px 40px 0px 0px $light-color;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: 0px 40px 0px 0px $theme-color,
                0px -40px 0px 0px $theme-color,
                40px 0px 0px 0px $theme-color,
                -40px 0px 0px 0px $theme-color,
                40px 40px 0px 0px $theme-color,
                -40px -40px 0px 0px $theme-color,
                40px -40px 0px 0px $theme-color,
                -40px 40px 0px 0px $theme-color;
  }
}
.community-table{
  width: 450px;
}